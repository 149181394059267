
.react-calendar__tile {
    border-radius: 10px;
    margin: 5px;
}

.react-calendar {
    border-radius: 10px;
}

.react-calendar__navigation__next2-button {
    border-top-right-radius: 10px;
}
.react-calendar__navigation__prev2-button {
    border-top-left-radius: 10px;
}

.flip-countdown {
    margin: 0 !important;
}