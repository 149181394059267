

.content-wrapper {
    height: 100vh;
}

.head-div {
    height: 80vh;
    padding: 100px;
}

.white-heading {
    -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: white;
    font-size: 150px;
    font-family: 'Concert One', cursive;
    margin: 15px 0 15px 0;
}

.white-heading:hover {
    -webkit-text-fill-color: white; /* Will override color (regardless of order) */
    cursor: pointer;
}

.orange-heading {
    -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: #ff8c37;
    font-size: 150px;
    font-family: 'Concert One', cursive;
    margin: 15px 0 15px 0;
}
.orange-heading:hover {
    -webkit-text-fill-color: #ff8c37;
    cursor: pointer;
}

.down-arrow {
    color: white;
    font-size: 100px !important;
    animation: bounce 1.1s infinite;
}

@keyframes bounce {
    0% {
        margin-top: 0;
    }
    50% {
        margin-top: 20px;
    }
    100% {
        margin-top: 0;
    }
}